  <nav
  *ngVar="{ currentUser: currentUser$ | async, isLoaded: isLoadedAccounts$ | async } as observables"
  class="nav-wrapper font-poppins relative"
  [ngClass]="[openNavigation || options.mobile ? 'naw-isOpen' : 'naw-isClose']"
>
  <div class="first-block">
    <div class="nav-logo-wrapper relative" [ngStyle]="{'min-height': companyName.offsetHeight+'px'}" [popper]="companyNameHover" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true">
      <button (click)="options.mobile ? '' : navToggle()" class="nav-logo-Wr" >
        <img class="cursor-pointer" src="../../../assets/haulex-logo-white.svg" class="nav-logo" />
      </button>
      <span #companyName class="company-nav text-center"
      >{{ observables?.currentUser?.company?.name || 'HaulEx' }}
      </span>
      @if(options.mobile){
        <i (click)="onNavMobileClose.emit()" class="fa-solid close-nav fa-xmark"></i>
      }
    </div>
    <!-- All Poppers have to be placed here, outside of container that has overflow-y: auto -->
    <popper-content #companyNameHover class="z-50" [ngClass]="{'hidden': openNavigation}" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover popover-company-name">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name" style="text-wrap: nowrap;">
          {{ observables?.currentUser?.company?.name || 'HaulEx' }}
        </div>
      </div>
     </popper-content>
    <popper-content #dashboard class="z-50" [ngClass]="{'hidden': openNavigation}" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Dashboard
        </div>
      </div>
    </popper-content>
    <popper-content #accountsList class="z-50" [ngClass]="{'hidden': openNavigation}" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          CRM
        </div>
      </div>
     </popper-content>
    <popper-content #carriersOrdersRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Loadboard
        </div>
      </div>
    </popper-content>
    <popper-content #routerTabBookNow class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name" style="text-wrap: nowrap;">
          Book Now!
        </div>
      </div>
    </popper-content>
    <popper-content #myOrdersRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name" style="text-wrap: nowrap;">
          My Orders
        </div>
      </div>
    </popper-content>
    <popper-content #brokersOrdersRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Leads
        </div>
      </div>
    </popper-content>
    <popper-content #tripsRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Trips
        </div>
      </div>
    </popper-content>
    <popper-content #tasksRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Tasks
        </div>
      </div>
    </popper-content>
    <popper-content #Calendar class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Calendar
        </div>
      </div>
    </popper-content>
    <popper-content #claimsRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Claims
        </div>
      </div>
    </popper-content>
    <popper-content #trackingRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Tracking
        </div>
      </div>
    </popper-content>
    <popper-content #recruiterRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Recruiter
        </div>
      </div>
    </popper-content>
    <popper-content #reportsRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Reports
        </div>
      </div>
    </popper-content>
    <popper-content #shopRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Shop
        </div>
      </div>
    </popper-content>
    <popper-content #expensesRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Expenses
        </div>
      </div>
    </popper-content>
    <popper-content #feedbackRead class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Feedback
        </div>
      </div>
    </popper-content>
    <popper-content #themesToggle class="z-50" [ngClass]="{'hidden': openNavigation || options.mobile}">
      <div class="shadow-sm popover popover_themes">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Theme
        </div>
      </div>
    </popper-content>
    <popper-content #accountInfo class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover popover_logo">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name" style="text-wrap: nowrap;">
          {{ observables?.currentUser?.name }}
        </div>
      </div>
    </popper-content>
    <popper-content #myCompany class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name" style="text-wrap: nowrap;">
          My Company
        </div>
      </div>
    </popper-content>
    <popper-content #subscription class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          {{ isSubscribed ? 'Unsubscribe' : 'Subscribe' }}
        </div>
      </div>
    </popper-content>
    <popper-content #Logout class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Logout
        </div>
      </div>
    </popper-content>
    <popper-content #Login class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover">
        <i class="fa-solid fa-caret-left popover_icon"></i>
        <div class="popover-name">
          Login
        </div>
      </div>
    </popper-content>
    <popper-content #AppVersion class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover popover_themes">
        <i class="fa-solid fa-caret-left popover_icon_version"></i>
        <div class="popover-name" style="text-wrap: nowrap;">
          App Version
        </div>
      </div>
    </popper-content>
    <popper-content #Schedule class="z-50" [ngClass]="{'hidden': openNavigation}">
      <div class="shadow-sm popover popover_themes">
        <i class="fa-solid fa-caret-left popover_icon_version"></i>
        <div class="popover-name" style="text-wrap: nowrap;">
          Schedule
        </div>
      </div>
    </popper-content>
    <div class="nav-items-wrapper">
      <div class="nav-items">
        <div class="nav-container" *ngIf=isLoggedIn>
          <a
            *appHasPermission="'dashboards_read'"
            (click)="onCloseNavigation()"
            [popper]="dashboard" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
            id="router-tab-dashboard"
            class="nav-item relative"
            [routerLink]="['/dashboards']"
            routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-chart-column navigations-icon" style="padding-left: 2px;"></i>
          </div>
            <span class="navigations-text">Dashboard</span>
          </a>
         <!-- dashboards -->
          <a
           *appHasPermission="'accounts_list'"
           (click)="onCloseNavigation()"
           [popper]="accountsList" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           id="router-tab-crm"
           class="nav-item relative"
           [routerLink]="['/crm']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-address-book navigations-icon" style="padding-left: 2px;"></i>
          </div>
          <span class="navigations-text">CRM</span></a>
          <a
           class="nav-item"
           [popper]="carriersOrdersRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           *appHasPermission="'carriers_orders_read'"
           (click)="onCloseNavigation()"
           id="router-tab-loadboard"
           [routerLink]="['/loadboard']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-arrow-up-wide-short navigations-icon"></i>
          </div>
          <span class="navigations-text">Loadboard</span></a>
          <a
           *ngIf="observables?.currentUser?.company.type === 'CARRIER'"
           [popper]="routerTabBookNow" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           id="router-tab-book-now"
           (click)="onCloseNavigation()"
           class="nav-item"
           [routerLink]="['/v/loadboard']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-truck-fast navigations-icon" style="color: rgb(255, 193, 7) !important"></i>
          </div>
          <span class="navigations-text" style="white-space: nowrap;">Book Now!</span></a>
          <a
           id="router-tab-my-orders"
           class="nav-item"
           [popper]="myOrdersRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           (click)="onCloseNavigation()"
           *appHasPermission="'my_orders_read'"
           [routerLink]="['/v/customer-loadboard']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-server navigations-icon minor-pad2px"></i>
          </div>
          <span class="navigations-text" style="white-space: nowrap;">My Orders</span></a>
          <a
           id="router-tab-leads"
           class="nav-item"
           [popper]="brokersOrdersRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           *appHasPermission="'brokers_orders_read'"
           (click)="onCloseNavigation()"
           [routerLink]="['/quotes']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-headset navigations-icon" style="padding-left: 2px;"></i>
          </div>
          <span class="navigations-text">Leads</span></a>
           <a
           id="router-tab-trips"
           class="nav-item"
           (click)="onCloseNavigation()"
           [popper]="tripsRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           *appHasPermission="'trips_read'"
           [routerLink]="['/trips']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-route navigations-icon minor-pad1px"></i>
          </div>
          <span class="navigations-text">Trips</span></a>
          <a
           id="router-tab-tasks"
           class="nav-item" *appHasPermission="'tasks_read'"
           [popper]="tasksRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           [routerLink]="['/tasks']"
           routerLinkActive="nav-link-color--active"
           (click)="onCloseNavigation()"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-list-check navigations-icon minor-pad1px"></i>
          </div>
          <span class="navigations-text">Tasks</span></a>
          <a
           id="router-tab-calendar"
           class="nav-item"
           *appHasPermission="'admin_read'"
           [routerLink]="['/calendar']"
           [popper]="Calendar" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           (click)="onCloseNavigation()"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-calendar-days navigations-icon minor-pad2px"></i>
          </div>
          <span class="navigations-text">Calendar</span>
          </a>
          <a
            id="router-tab-schedule"
            class="nav-item relative"
            *appHasPermission="'schedules_read'"
            [routerLink]="['/schedules']"
            [popper]="Schedule" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
            (click)="onCloseNavigation()"
            routerLinkActive="nav-link-color--active"
          >
            <div class="nav-icon-wrapper">
              <i class="fa-solid fa-calendar navigations-icon minor-pad2px"></i>
            </div>
            <span class="navigations-text">Schedule Requests</span>
            @if (schedulesCount) {
              <span 
              class="navigations-count"
              [ngClass]="{
                'count_mid': schedulesCount > 9, 
                'count_lar': schedulesCount > 99, 
                'count_is_open': openNavigation,
                'count_is_open_mid': openNavigation && schedulesCount > 9,
                'count_is_open_larg': openNavigation && schedulesCount > 99,
                'count_is_open_mobile': options.mobile
                }">{{schedulesCount}}</span>

            }
          </a>
          <a
           class="nav-item"
           *appHasPermission="'claims_read'"
           [popper]="claimsRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           id="router-tab-claims"
           (click)="onCloseNavigation()"
           [routerLink]="['/claims']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-car-burst navigations-icon"></i>
          </div>
          <span class="navigations-text">Claims</span></a>
          <a
           class="nav-item"
           *appHasPermission="'tracking_read'"
           id="router-tab-tracking"
           [routerLink]="['/tracking']"
           [popper]="trackingRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           id="router-tab-tracking"
           (click)="onCloseNavigation()"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-location-arrow navigations-icon minor-pad2px" style="padding-left: 1px;"></i>
          </div>
          <span class="navigations-text">Tracking</span></a>
          <a
           class="nav-item"
           *appHasPermission="'recruiter_read'"
           id="router-tab-recruiter"
           [routerLink]="['/recruiter']"
           [popper]="recruiterRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           (click)="onCloseNavigation()"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-people-arrows navigations-icon"></i>
          </div>
          <span class="navigations-text">Recruiter</span></a>
          <a
           class="nav-item"
           *appHasPermission="'reports_read'"
           id="router-tab-reports"
           (click)="onCloseNavigation()"
           [popper]="reportsRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           [routerLink]="['/reports']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-chart-line navigations-icon" style="padding-left: 1.5px;"></i>
          </div>
          <span class="navigations-text">Reports</span></a>
          <a
           class="nav-item"
           [popper]="shopRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           *appHasPermission="'shop_read'"
           id="router-tab-shop"
           (click)="onCloseNavigation()"
           [routerLink]="['/shop']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-wrench navigations-icon minor-pad1px"></i>
          </div>
          <span class="navigations-text">Shop</span></a>
          <a
           class="nav-item"
           *appHasPermission="'expenses_read'"
           id="router-tab-expenses"
           [popper]="expensesRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           (click)="onCloseNavigation()"
           [routerLink]="['/expenses']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-magnifying-glass-dollar navigations-icon minor-pad1px"></i>
          </div>
          <span class="navigations-text">Expenses</span></a>
           <a
           class="nav-item"
           *appHasPermission="'feedback_read'"
           id="router-tab-feedback"
           [popper]="feedbackRead" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
           (click)="onCloseNavigation()"
           [routerLink]="['/feedback']"
           routerLinkActive="nav-link-color--active"
          >
          <div class="nav-icon-wrapper">
            <i class="fa-solid fa-star navigations-icon"></i>
          </div>
          <span class="navigations-text">Feedback</span></a>
         </div>
         <div class="nav-container-bottom">
           <div *ngIf=isLoggedIn class="toggle-wrapper">
             <div class="themes-toggle" [popper]="themesToggle" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true">
               <label for="theme-switch-toggle" class="theme-switch">
                 <input (change)="onToggleTheme()" type="checkbox" [checked]="darkThemeEnabled" id="theme-switch-toggle">
                 <span class="switch__slider_theme"></span>
                 <i class="fa-solid fa-sun slider-sun-icon absolute" [ngStyle]="{ 'color': darkThemeEnabled ? '#D1D5DB' : '#374151' }"></i>
                 <i class="fa-solid fa-moon switch_theme-moon" [ngStyle]="{ 'color': !darkThemeEnabled ? '#D1D5DB' : '#374151' }"></i>
               </label>
             </div>
           </div>

           <div *ngIf=isLoggedIn class="sidebar-account-info overflow-hidden relative" [popper]="accountInfo" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true">
             <img [src]="'../../../assets/avatar-default.png'" class="account-avatar-img" *ngIf="!openNavigation && !options.mobile" alt="">
             <div class="account-wrapper" [ngClass]="{'account-wrapper-show': openNavigation || options.mobile}">
               <img [src]="'../../../assets/avatar-default.png'" class="account-avatar-img" alt="">
               <div class="account-info">
                 <p class="account-name">{{ observables?.currentUser?.name }}</p>
                 <p *ngIf="roles$ | async as roles" class="account-company"><span *ngIf="roles[observables?.currentUser?.roleId]?.name !== ''">Role: </span>
                   <span *ngIf="roles[observables?.currentUser?.roleId]?.name !== 'Admin'" class="font-semibold text-white">{{roles[observables?.currentUser?.roleId]?.name}}</span>
                   <span *appHasPermission="'admin_read'" [routerLink]="['/admin']" (click)="onCloseNavigation()" class="font-semibold text-white cursor-pointer">Admin</span>
                 </p>
               </div>
             </div>
           </div>


           <div *ngIf=isLoggedIn class="nav-container nav-container-footer">
             <a
               class="nav-item"
               (click)="onCloseNavigation()"
               [popper]="myCompany" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
               id="current-user-menu-company"
               *ngIf="observables?.currentUser?.permissions?.my_orders_navbar != true"
               [routerLink]="['/crm/accounts', observables?.currentUser?.company._id]"
             >
             <div class="icon-wrapper">
              <i class="fa-solid fa-building navigations-icon"></i>
             </div>
             <span class="navigations-text" style="white-space: nowrap;">My Company</span></a>


             <a
               id="current-user-menu-subscription"
               [popper]="subscription" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
               class="nav-item"
               *ngIf="observables?.currentUser?.permissions?.my_orders_navbar != true"
               (click)="togglePushNotifications()"
             >
             <div class="icon-wrapper">
              <i *ngIf="!isSubscribed" class="fa-solid fa-bell navigations-icon" style="color: #ffffff"></i>
              <i *ngIf="isSubscribed" class="fa-solid fa-bell navigations-icon" style="color: #007bff !important"></i>
             </div>
               <span class="navigations-text">{{ isSubscribed ? 'Unsubscribe' : 'Subscribe' }}</span>
             </a>

             <a class="nav-item cursor-pointer" *ngIf="isLoggedIn" (click)="logout(); onCloseNavigation()" [popper]="Logout" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true">
               <i class="fas fa-sign-out-alt navigations-icon"></i><span class="navigations-text logout">Logout</span>
             </a>

           </div>
           <a
             class="nav-item"
             (click)="onCloseNavigation()"
             *ngIf="!isLoggedIn"
             [popper]="Login"
             [popperDisableStyle]="true"
             [popperPlacement]="NgxPopperjsPlacements.RIGHT"
             [popperTrigger]="NgxPopperjsTriggers.hover"
             [popperHideOnClickOutside]="true"
             routerLink="/login"
             routerLinkActive="active">
             <div class="icon-wrapper">
              <i class="fas fa-sign-out-alt navigations-icon"></i>
            </div>
             <span class="navigations-text">Login</span>
           </a>

         </div>
      </div>

      <div *ngIf="isLoggedIn"  class="version-wr">
        <a id="current-user-menu-ver" (click)="onCloseNavigation()"
        [routerLink]="['/release-notes']"
        [popper]="AppVersion" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.RIGHT" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true"
        ><span class="nav-version">App Version:</span> <span class="nav-version-num">{{ appVersion }}</span></a>

      </div>

    </div>
  </div>

</nav>
