<div class="">
    <div class="header">
        @if (modaltype == 'touchPoint') {
            <span>Schedule Follow-Up</span> 
        } @else if (modaltype == 'history') {
            <span>Log History</span>
        } @else {
            <span>{{form.get('subject')?.value}}</span>
        }
        <button type="button" (click)="close()"><i class="fas fa-close text-gray-500 text-lg"></i></button>
    </div>
    <div class="content_log">
        <form [formGroup]="form">
            <div class="info">
                <div class="customer">
                    <p class="customer_name">{{form.get('creator')?.value?.name}}</p>
                    <div class="customer_contacts">
                        @if (contact?.phones?.length) {
                            @for(phone of contact?.phones; track $index) {
                                @if (phone?.value) {
                                    <div class="customer_contac_item">
                                        <input (change)="onPhoneCheckboxChange($event, phone?.value)" [id]="phone?.value" type="checkbox" [checked]="form.get('phones')?.value?.includes(phone?.value)" class="custom-checkbox text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                        <label [for]="phone?.value">{{applyMask(phone?.value, inputMasks.phone)}}</label>
                                    </div>
                                }
                            }
                        }

                        @if (contact?.emails?.length) {
                            @for(email of contact?.emails; track $index) {
                                @if (email?.value) {
                                    <div class="customer_contac_item">
                                        <input (change)="onEmailCheckboxChange($event, email?.value)" [id]="email?.value" [checked]="form.get('emails')?.value?.includes(email?.value)" type="checkbox" class="custom-checkbox text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                        <label [for]="email?.value">{{truncateString(email?.value)}}</label>
                                    </div>
                                }
                            }
                        }
                    </div>
                    @if (
                    (emailsPhonesWarning && form.get('emails')?.invalid && form.get('phones')?.invalid) ||
                    (form.get('types')?.value?.includes('Email') && !form.get('emails')?.value?.length) ||
                    (form.get('types')?.value?.includes('SMS') && !form.get('phones')?.value?.length) ||
                    (form.get('types')?.value?.includes('Call') && !form.get('phones')?.value?.length)
                    ) {
                        <p class="please_select">Please select email / phone</p>
                    }
                </div>
                <div class="select_cont">
                    @if ((modaltype == 'history' || modaltype == 'edit') && !noTag) {
                        <div class="select_cont_item">
                            <div class="outcomes">
                                @for(tag of outcomeGreen; track $index) {
                                    <button 
                                        class="outcome_item" 
                                        (click)="onChangeOutcome(tag?.name)" 
                                        [ngStyle]="{'color:': tag?.color, 'background': this.form.get('tag').value !== tag?.name ? tag?.bg : null}" 
                                        [ngClass]="{'active_outcome_green': this.form.get('tag').value == tag?.name}"
                                    >
                                        {{tag?.name}}
                                    </button>
                                }
                            </div>
                            <div class="outcomes">
                                @for(tag of outcomeYellow; track $index) {
                                    <button 
                                        class="outcome_item" 
                                        (click)="onChangeOutcome(tag?.name)" 
                                        [ngStyle]="{'color:': tag?.color, 'background': this.form.get('tag').value !== tag?.name ? tag?.bg : null}" 
                                        [ngClass]="{'active_outcome_yellow': this.form.get('tag').value == tag?.name}"
                                    >
                                        {{tag?.name}}
                                    </button>
                                }
                            </div>
                            <div class="outcomes">
                                @for(tag of outcomeRed; track $index) {
                                    <button 
                                        class="outcome_item" 
                                        (click)="onChangeOutcome(tag?.name)" 
                                        [ngStyle]="{'color:': tag?.color, 'background': this.form.get('tag').value !== tag?.name ? tag?.bg : null}" 
                                        [ngClass]="{'active_outcome_red': this.form.get('tag').value == tag?.name}"
                                    >
                                        {{tag?.name}}
                                    </button>
                                }
                            </div>
                        </div>
                    }
                    <div class="select_cont_item cont_item_btns">
                        @if (modaltype == 'history' || modaltype == 'touchPoint' || modaltype == 'edit_empty_schedule' || isFollowUp) {
                            <div class="touchpoints">
                                <button
                                    class="touchpoint_item" 
                                    (click)="onChangeTypes('Call')" 
                                    [ngClass]="{'active_coin_btn': this.form.get('types').value?.includes('Call'), 'no_active_coin_btn': !this.form.get('types').value?.includes('Call')}"
                                >
                                    Call
                                </button>
                                <button
                                    class="touchpoint_item" 
                                    (click)="onChangeTypes('SMS')" 
                                    [ngClass]="{'active_coin_btn': this.form.get('types').value?.includes('SMS'), 'no_active_coin_btn': !this.form.get('types').value?.includes('SMS')}"
                                >
                                    SMS
                                </button>
                                <button
                                    class="touchpoint_item" 
                                    (click)="onChangeTypes('Email')" 
                                    [ngClass]="{'active_coin_btn': this.form.get('types').value?.includes('Email'), 'no_active_coin_btn': !this.form.get('types').value?.includes('Email')}"
                                >
                                    Email
                                </button>
                            </div>
                        }
                        @if (modaltype == 'history') {
                            <div class="directions">
                                <button
                                    class="touchpoint_item" 
                                    (click)=" this.form.get('direction')?.setValue('Inbound')" 
                                    [ngClass]="{'active_coin_btn': this.form.get('direction').value == 'Inbound', 'no_active_coin_btn': this.form.get('direction').value !== 'Inbound'}"
                                >
                                    Inbound
                                </button>
                                <button
                                    class="touchpoint_item" 
                                    (click)=" this.form.get('direction')?.setValue('Outbound')" 
                                    [ngClass]="{'active_coin_btn': this.form.get('direction').value == 'Outbound', 'no_active_coin_btn': this.form.get('direction').value !== 'Outbound'}"
                                >
                                    Outbound
                                </button>
                            </div>
                        }
                        @if (modaltype == 'edit' && !isFollowUp) {
                            <app-input
                                [idInfo]="'touchpoint'"
                                [value]="types" 
                                [isReadOnly]="true"
                                [label]="'Touchpoint'"
                            ></app-input>
                        }
                    </div>
                    <div class="select_types">
                        @if (
                            (typesWarning && form.get('types')?.invalid && !form.get('types')?.value?.length)
                        ) {
                            <p class="please_select" style="margin-top: 0px;">Please select Touch Point</p>
                        }
                    </div>
                    <div class="flex items-center select_cont_dates select_cont_item">
                        <app-date
                            id="task-follow-up-date"
                            [isContainerStyle]="true"
                            [options]="{ placeholder: 'Date', right: '90px', required: dateWarning && this.form.get('sentAt')?.invalid }"
                            [parent]="form.value"
                            [time]="true"
                            [startKey]="'sentAt'"
                            (onSave)="form.patchValue({'sentAt': $event})"
                        ></app-date>
                    </div>
                </div>
            </div>
            @if (form.get('types')?.value?.includes('Call')) {
                <div class="descr flex flex-col">
                    <span class="templ_title">Schedule Call</span>
                    <textarea 
                        id="message" 
                        rows="4" 
                        formControlName="description"
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder="Internal Notes"
                    ></textarea>
                </div>
            }
            @if (form.get('types')?.value?.includes('SMS') || form.get('types')?.value?.includes('Email')) {
                <div class="template_name">
                    <app-select
                        [label]="'Template'"
                        [idInfo]="'template-name'"
                        (onSave)="changeTemplate($event)"
                        [value]="form.get('templateId')?.value"
                        [list]="templates"
                        [keyName]="{key:'_id', name:'name'}"
                        [placeholder]="'Sub'"
                    ></app-select>
                    <app-input
                        [idInfo]="'subject'"
                        [label]="'Subject'"
                        [value]="form.get('subject')?.value"
                        (onChange)="form.patchValue({
                            subject: $event
                        })"
                    ></app-input>
                </div>
                <div class="templates" [ngClass]="{
                    'edit_sms_email': (this.form.get('filteredTypes').value[0] == 'Email' || this.form.get('filteredTypes').value[0] == 'SMS') && this.form.get('filteredTypes').value?.length == 1
                }">
                    @if (form.get('types')?.value?.includes('SMS')) {
                        <div class="template_item">
                            <span class="templ_title">SMS</span>
                            <div class="editor_sms" [ngClass]="{'input_invalid': smsWarning && this.form.get('smsBody')?.invalid}">
                                <textarea 
                                    id="sms" 
                                    rows="8" 
                                    formControlName="smsBody"
                                    style="resize: none;"
                                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                    placeholder="SMS message..."
                                ></textarea>
                            </div>
                        </div>
                    }
                    @if (form.get('types')?.value?.includes('Email')) {
                        <div class="template_item">
                            <span class="templ_title">Email</span>
                            <div class="editor" [ngClass]="{'input_invalid': emailWarning && this.form.get('emailBody')?.invalid}">
                                <app-editor [ngClass]="{'editor_full': this.form.get('filteredTypes').value[0] == 'Email' && this.form.get('filteredTypes').value?.length == 1, 'editor_full_email': this.form.get('filteredTypes').value.includes('Email') && this.form.get('filteredTypes').value.includes('SMS')}" [content]="form.get('emailBody')?.value" (onChange)="form.patchValue({
                                    emailBody: $event
                                })"></app-editor>
                            </div>
                        </div>
                    }
                </div>
            }
            <div class="btns"
            [ngClass]="{
                'edit_sms_email_btn': !((this.form.get('filteredTypes').value[0] == 'Email' || this.form.get('filteredTypes').value[0] == 'SMS') && this.form.get('filteredTypes').value?.length == 1)
            }">
                @if (modaltype == 'edit' && canDelete) {
                    <button class="delete" (click)="deleteFollowUp(form.value)">Delete</button>
                }
                <div class="ml-auto">
                    @if ((!isTask || modaltype == 'edit_empty_schedule') && !editHistory) {
                        <app-btn-toggle
                            [value]="this.form.get('filledLater').value"
                            [label]="'Fill Template Later'"
                            [idInfo]="'later-toggle'"
                            (onToggle)="this.form.patchValue({filledLater: $event})"
                        >
                        </app-btn-toggle>
                    }
                </div>
                <button class="cancel" (click)="close()">Cancel</button>
                @if (modaltype == 'edit') {
                    <button class="log_activity" (click)="send()">Save Changes</button>
                } @else if (modaltype == 'history') {
                    <button class="log_activity" (click)="send()">Log History</button>
                } @else {
                    <button class="log_activity_schedule" (click)="send()">Schedule {{this.form.get('types')?.value?.length || 0}} Events</button>
                }
            </div>
        </form>
    </div>
</div>